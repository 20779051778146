// This composable is usually extended by the layer
// eg. useCareersGlobalData

const apiEndpoints = new Map([
  ['navigation', '/api/v3/core_data/navigation'],
  ['min', '/api/v3/core_data/min'],
  ['filter-labels', '/api/v3/core_data/filter-labels'],
  ['investor-relations-labels', '/api/v3/core_data/investor-relations-labels'],
  ['global-product', '/api/v3/core_data/global-product'],
  ['form-labels', '/api/v3/core_data/form-labels'],
  ['global-forms', '/api/v3/core_data/global-forms'],
  ['global-industry', '/api/v3/core_data/global-industry'],
  ['global-offices', '/api/v3/core_data/global-offices']
])

const baseCategories = ['min', 'navigation']

export const useGlobalData = () => {
  // const runtimeConfig = useRuntimeConfig()
  // const { getLocaleIso } = useLocales()
  const { locale } = useI18n()
  // const localeRef = ref('en') // TODO remove
  // const locale = computed(() => getLocaleIso(localeRef.value)) // TODO: Check if makes sense that it does not apply anymore

  const labels = computed(() => ({
    ...localeGlobalContentData.value?.labels || {},
    ...localeGlobalContentData.value?.careerLabels || {},
    ...localeGlobalContentData.value?.formLabels || {},
    ...localeGlobalContentData.value?.filterLabels || {},
    ...localeGlobalContentData.value?.investorLabels || {},
    ...localeGlobalContentData.value?.partnerLabels || {},
    ...localeGlobalContentData.value?.charityLabels || {},
    ...localeGlobalContentData.value?.knowledgeHubLabels || {},
    ...localeGlobalContentData.value?.globalDataProductLabels || {},
    ...localeGlobalContentData.value?.paymentMethodsLabels || {},
    ...localeGlobalContentData.value?.paymentsLabels || {},
    ...localeGlobalContentData.value?.paymentCountryLabels || {}
  }))

  // const route = useRoute()
  // const preview = useRuntimeConfig().public.preview

  const globalContentData = useState('globalData', () => null)

  const setGlobalContentData = (data: any) => {
    globalContentData.value = data
  }

  const getGlobalCategoryEndpoint = (cat: string) => {
    return apiEndpoints.get(cat)
  }

  const localeGlobalContentData = computed(() => globalContentData.value?.[locale.value] || {})

  const generateDataPromisesForContentType = (contentType: string, specificCategoriesMap: Map<string, string[]>, specificEndpoints?: Map<string, string>, locale?: string) => {
    const specificCategories = specificCategoriesMap.get(contentType)
    const categories = baseCategories.concat(specificCategories || [])

    const promises = categories.map((cat) => {
      if (specificEndpoints?.has(cat)) {
        const endpoint = specificEndpoints.get(cat)!
        if (locale) {
          const midlewareEndpoint = '/api/v3/core_data/locale-wrap'
          const query = { locale, endpoint }
          return $fetch(midlewareEndpoint, { query })
        }
        return $fetch(endpoint)
      }

      const globalEndpoint = getGlobalCategoryEndpoint(cat)
      if (!globalEndpoint) {
        return null
      }

      if (locale) {
        const midlewareEndpoint = '/api/v3/core_data/locale-wrap'
        const query = { locale, endpoint: globalEndpoint }
        return $fetch(midlewareEndpoint, { query })
      }

      return $fetch(globalEndpoint)
    })
    return promises.filter(Boolean)
  }

  const ogImage = computed(() => localeGlobalContentData.value.image)

  return {
    labels,
    ogImage,
    // baseCategories,
    setGlobalContentData,
    generateDataPromisesForContentType,
    getGlobalCategoryEndpoint,
    globalContentData,
    localeGlobalContentData
  }
}
