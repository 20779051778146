import { useRoutesUtils } from '../utils/useRoutesUtils'
import { useRoutes } from '../utils/useRoutes'

export const useI18nPageInfo = () => {
  const { wrapLocale, isHome } = useRoutesUtils()
  const store = useRoutesStore()
  const i18nParams = useState('i18n-params', () => { })
  const route = useRoute()

  const initSEO = async ({ sysId = 'none', targetSite = 'all', locale = 'en' }) => {
    await store.retrieveRoutes()
    const { getRouteParams } = useRoutes(store.routes, wrapLocale(locale))

    const routeParams = getRouteParams(sysId, isHome(route.path), route.params) || {}
    i18nParams.value = routeParams
    return routeParams
  }

  return {
    initSEO,
    i18nParams
  }
}
